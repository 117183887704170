<template>
    <CCard>
        <CCardHeader>
            Advancer Öğretmenler
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol class='col-6'>
                    <CInput label='Arama: ' placeholder='Aramak istediğiniz öğretmeni yazınız...' :horizontal="{ label: 'w-auto', input: 'w-100'}" v-debounce:600ms='changeFilterValue' />
                </CCol>
                <CCol class='float-right'>
                    <CSelect
                        label='Filtrele: '
                        placeholder='Hesap Onay Durumu Seçiniz'
                        :horizontal="{ label: 'w-auto', input: 'w-100'}"
                        @update:value='FiltersApprovedTeachers($event)'
                        :options="[
                  { value: null, label: 'Hepsi'},
                  { value: 'ONLY_APPROVED_TEACHERS', label: 'Onaylı Öğretmenler'},
                  { value:'ONLY_UNAPPROVED_TEACHERS', label: 'Onaysız Öğretmenler'}
                  ]"
                    />
                </CCol>
            </CRow>
            <CDataTable
                class='mb-0 table-outline'
                hover
                :items='tableItems'
                :fields='tableFields'
                head-color='light'
                :loading='isDataLoading'
                :items-per-page='itemsPerPage'
            >
                <template #no-items-view>
                    <CRow>
                        <CCol class='col-12 font-3xl text-center'>
                            <CIcon name='cil-x' />
                            Veri Bulunamadı
                        </CCol>
                    </CRow>
                </template>
                <td slot='id' slot-scope='{item}'>
                    <div>{{ item.id }}</div>
                    <div class='small text-muted'>

                    </div>
                </td>
                <td slot='avatar1X' slot-scope='{item}'>
                    <div class='clearfix'>
                    </div>
                    <img
                        style='width: 64px; height: 64px'
                        @error='fallback'
                        class='c-avatar-img'
                        :src="item.avatar1X ? item.avatar1X : ''"
                    />
                </td>
                <td
                    slot='name'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.name }}</div>
                </td>
                <td
                    slot='isOnline'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <CBadge :color='isTeacherOnlineColor(item.isOnline) '>{{
                            item.isOnline ? 'Çevrimiçi' : 'Çevrimdışı'
                        }}
                    </CBadge>
                </td>

                <td
                    slot='registeredAt'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.registeredAt | formatDate }}</div>
                </td>
                <td
                    slot='eMail'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.eMail }}</div>
                </td>
                <td
                    slot='accentType'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ accentText(item.accentType) }}</div>
                </td>
                <td
                    slot='rate'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.rate }}</div>
                </td>
                <td
                    slot='description'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.description }}</div>
                </td>
                <td
                    slot='isAccountApproved'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <CBadge :color='isAccountApprovedColor(item.isAccountApproved)'>{{ item.isAccountApproved ? 'Onaylandı' : 'Onaylanmadı' }}</CBadge>

                </td>
                <td
                    slot='isZoomCreated'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <CBadge :color='isAccountZoomColor(item.isZoomCreated)'>{{ item.isZoomCreated ? 'oluşturuldu' : 'Oluşturulmadı' }}</CBadge>

                </td>
                <td slot='manage' slot-scope='{item}'>
                    <CCol col='12' class='text-center'>
                        <router-link :to="'/ogretmeni-duzenle/' + item.id" tag='button' role='button' block
                                     class='btn btn-success mb-1 ml-1'>
                            <CIcon name='cil-pencil' />
                        </router-link>
                        <CButton @click='deleteTeacherAlert(item.id)' color='danger' class='ml-1 mb-1'>
                            <CIcon name='cil-x' />
                        </CButton>
                    </CCol>
                </td>
            </CDataTable>
            <CPagination
                v-if='totalPages !== 0'
                :pages='totalPages'
                :active-page='currentPage'
                :activePage.sync='currentPage'
                align='center'
                class='mt-3'
            />
        </CCardBody>
    </CCard>
</template>

<script>
import { TeacherAdministration } from '@/services/api-service'

export default {
    name: 'Teachers',
    components: {},

    mounted() {
        this.loadTeachers()
    },
    watch: {
        filterValue() {
            this.loadTeachers()
        },
        currentPage() {
            this.loadTeachers()
        },
        approvementState() {
            this.loadTeachers()
        },
    },
    data() {
        return {
            approvementState: undefined,
            filterValue: '',
            totalPages: 1,
            currentPage: 1,
            itemsPerPage: 50,
            isDataLoading: false,
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'avatar1X', label: '' },
                { key: 'name', label: 'Adı', _classes: 'text-center' },
                { key: 'isOnline', label: 'Durumu', _classes: 'text-center' },
                { key: 'registeredAt', label: 'Kayıt Tarihi', _classes: 'text-center' },
                { key: 'eMail', label: 'Mail', _classes: 'text-center' },
                { key: 'accentType', label: 'Aksan', _classes: 'text-center' },
                { key: 'rate', label: 'Puanı', _classes: 'text-center' },
                { key: 'description', label: 'Açıklama', _classes: 'text-center' },
                { key: 'isAccountApproved', label: 'Hesap Onaylı mı?', _classes: 'text-center' },
                { key: 'isZoomCreated', label: 'Yakınlaştırma Oluşturuldu mu?', _classes: 'text-center' },
                { key: 'manage', label: 'Yönet' },
            ],
        }
    },
    methods: {
        async FiltersApprovedTeachers(value) {
            this.approvementState = value == null ? undefined : value
        },
        accentText(accent) {
            switch (accent) {
                case 'NATIVE_US':
                    return 'Amerikan Aksanı'
                case 'TURKISH':
                    return 'Türkçe'
                case 'OTHER':
                    return 'Diğer'
                case 'NATIVE_BRITISH':
                    return 'İngiliz Aksanı'
                default:
                    return ''
            }
        },
        fallback(event) {
            event.target.src = 'https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png'
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        getBadge(status) {
            return status === 'Tamamlandı' ? 'success'
                : status === 'İşlem Yapılmadı' ? 'warning'
                    : status === 'Reddedildi' ? 'danger' : 'primary'
        },
        isAccountApprovedColor(status) {
            switch (status) {
                case 'true':
                    return 'success'
                case 'false':
                    return 'danger'
                default:
                    return 'secondary'
            }
        },
        isAccountZoomColor(status) {
            switch (status) {
                case 'true':
                    return 'success'
                case 'false':
                    return 'danger'
                default:
                    return 'secondary'
            }
        },
        isTeacherOnlineColor(category) {
            switch (category) {
                case 'ONLY_APPROVED_TEACHERS':
                    return 'success'
                case 'ONLY_UNAPPROVED_TEACHERS':
                    return 'secondary'
                default:
                    return 'light'
            }
        },
        async loadTeachers() {
            this.isDataLoading = true
            const result = await TeacherAdministration.listTeachersForManagement(
                this.filterValue,
                this.approvementState == null ? undefined : this.approvementState.toUpperCase(),
                this.currentPage,
            )
            this.tableItems = result.data.teachers
            this.totalPages = result.data.pagination.totalPages
            this.isDataLoading = false
        },
        changeFilterValue(newValue) {
            this.filterValue = newValue
        },
        deleteTeacherAlert(id) {
            this.$swal({
                title: 'Öğretmeni KVKK gereği Silmek istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.tableItems = this.tableItems.filter(r => r.id !== id)
                    await TeacherAdministration.deleteTeacherAccountDueGDPR(id, Date.now())
                    await this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
    },
}
</script>

